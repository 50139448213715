import Loadable from "@loadable/component";

import AsyncMobileLoadingEntityCard from "../AsyncMobileLoadingEntityCard";

const PodcastMobileCard = Loadable(() => import("./PodcastMobileCard"));

function PodcastMobileCardAsyncLoading(props) {
  return <AsyncMobileLoadingEntityCard />;
}

const PodcastMobileCardContainer = (props) => {
  return (
    <PodcastMobileCard
      {...props}
      fallback={<PodcastMobileCardAsyncLoading />}
    />
  );
};

export default PodcastMobileCardContainer;
