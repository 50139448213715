import { css } from "aphrodite";
import { List } from "immutable";
import PropTypes from "prop-types";
import { memo } from "react";
import { Link } from "react-router-dom";

import ShowMoreContainer from "components/Common/ShowMoreContainer";

import getCategoryText from "utils/entity/getCategoryText";
import getCategoryUrl from "utils/entity/getCategoryUrl";
import getTagUrl from "utils/entity/getTagUrl";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";

const baseStyles = {
  categoriesContainer: {
    ...gStyles.textEllipsis,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  category: {
    ...gStyles.fontSemiBold,
    color: "var(--color-neutral-d3)",
    fontSize: ".625rem",
    backgroundColor: "var(--color-neutral-l2)",
    borderRadius: 13,
    padding: "10px 19px 6px",
    marginRight: "1rem",
    marginBottom: "1rem",
    lineHeight: "1",
    textTransform: "uppercase",
    transition:
      "background-color 200ms cubic-bezier(0.465, 0.183, 0.153, 0.946)",

    ...getHoverQuery({
      color: "var(--color-neutral-d4)",
    }),
  },
  mobileCategory: {
    ...gStyles.fontSemiBold,
  },
  moreCategories: {
    ...gStyles.fontMedium,
    fontSize: ".75rem",
    color: colours.white,
    marginBottom: "1rem",
  },
  mobileMoreCategories: {
    opacity: "0.7",
    padding: ".3rem",
  },
};

const mediumStyles = {
  categoriesContainer: {
    X_justifyContent: "center",
    position: "relative",
  },
  category: {
    ...gStyles.fontSemiBold,
    padding: ".5rem 1.2rem",
    marginRight: ".5rem",
    fontSize: ".75rem",
    flexWrap: "wrap",
    marginBottom: ".5rem",
  },
  moreCategories: {
    padding: ".5rem",
  },
};

const largeStyles = {
  category: {
    ...gStyles.fontSemiBold,
    borderRadius: "100px",
    padding: ".6125rem 1.2rem",
    marginRight: "0.625rem",
    fontSize: "0.8125rem",
    flexWrap: "wrap",
    marginBottom: ".5rem",
    textTransform: "none",
    color: colours.oldSecondary,
  },
};

const CategoryTags = (props) => {
  const {
    medium,
    large,
    maxCharacters,
    urlPath,
    useShowMoreContainer,
    categories: passedCategories,
    type,
    toggleHeight,
    showMoreStyles,
  } = props;
  const { styles } = useStyles(
    [baseStyles, medium && mediumStyles, large && largeStyles],
    props
  );

  let totalCharacters = 0;
  const allCategories =
    passedCategories &&
    passedCategories.filter((cat) => {
      const catTex = getCategoryText(cat);

      return !!catTex && catTex.trim() !== "";
    });

  const categories = allCategories
    ? allCategories.filter((category) => {
        const categoryTextLength = getCategoryText(category).length;

        totalCharacters += categoryTextLength;

        return totalCharacters <= maxCharacters && categoryTextLength > 0;
      })
    : List();

  const categoriesSize = categories.size;

  if (!categories || categoriesSize < 1) {
    return <div />;
  }

  const renderContent = () => (
    <div className={css(styles.categoriesContainer)}>
      {categories.map((category, index) => {
        const key = `${index}-${getCategoryText(category)}`;

        return (
          <Link
            key={key}
            className={css(styles.category)}
            to={
              type === "category"
                ? getCategoryUrl(category, urlPath || "/podcasts")
                : getTagUrl(category, urlPath || "/episodes")
            }
            title={`Browse ${getCategoryText(category, {
              singular: true,
            })} podcasts`}
          >
            {getCategoryText(category)}
          </Link>
        );
      })}
    </div>
  );

  return useShowMoreContainer ? (
    <ShowMoreContainer
      toggleHeight={toggleHeight}
      hideOverlay
      shrink
      checkForContentChanges
      styles={showMoreStyles}
      autoOnServer
    >
      {renderContent()}
    </ShowMoreContainer>
  ) : (
    renderContent()
  );
};

CategoryTags.propTypes = {
  categories: PropTypes.instanceOf(List),
  medium: PropTypes.bool,
  maxCharacters: PropTypes.number,
  type: PropTypes.string,
  urlPath: PropTypes.string,
  useShowMoreContainer: PropTypes.bool,
  toggleHeight: PropTypes.number,
  showMoreStyles: PropTypes.object,
  large: PropTypes.bool,
};

CategoryTags.defaultProps = {
  categories: List([]),
  medium: false,
  maxCharacters: 30,
  type: "category",
  urlPath: undefined,
  useShowMoreContainer: true,
  toggleHeight: 145,
  showMoreStyles: null,
  large: false,
};

export default memo(CategoryTags);
