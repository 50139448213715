import React from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { capitalizeWords } from "utils/case";
import getVerifiedBannerData from "utils/verifiedBanner/getVerfiedBannerData";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  bannerContainer: {
    width: "100%",
    height: "max-content",
    display: "grid",
    gridTemplateColumns: "2.438rem 5.313rem 1fr",
    gridGap: "1rem",
    borderRadius: 0,
    padding: "0.5rem",
    background:
      "linear-gradient(90deg, rgba(253,218,232,1) 0%, rgba(255,222,249,1) 50%, rgba(238,223,249,1) 100%)",
    alignItems: "Center",
    marginBottom: "2rem",

    [ScreenSizes.lgAndAbove]: {
      borderRadius: 8,
    },
  },
  bannerverifiedIcon: {
    width: "2.438rem",
  },
  bannerPartnerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.313rem",
    marginLeft: "-0.625rem",
    alignItems: "center",
  },
  bannerPartnerImage: {
    height: "1.3rem",
  },
  bannerPartnerSlogan: {
    ...gStyles.avalonBold,
    fontSize: "0.75rem",
    lineHeight: 1,
    margin: 0,
  },
  bannerExplainer: {
    ...gStyles.avalonBold,
    fontSize: "0.75rem",
    letterSpacing: 0,
    lineHeight: "0.938rem",
    margin: 0,
  },
  bannerInfoIcon: {
    color: colours.black,
    fontSize: "0.875rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  bannerPartnerText: {
    margin: 0,
    ...gStyles.avalonBold,
    fontSize: "1.1rem",
  },
};

export default function VerifiedBanner(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { partner } = props;

  const {
    verfiedLogo,
    verifiedIcon,
    verfiedSlogan,
    verfiedText,
    verfiedTextAbbreviated,
  } = getVerifiedBannerData(partner);

  return (
    <div className={css(styles.bannerContainer)}>
      <img
        height="max-content"
        width="2.438rem"
        data-testid="verifiedIcon"
        src={verifiedIcon}
        alt="verfied icon"
        className={css(styles.bannerverifiedIcon)}
      />

      <div className={css(styles.bannerPartnerContainer)}>
        {verfiedText ? (
          <BasicTooltip renderTooltip={() => verfiedText}>
            {(tooltipProps) => (
              <p
                {...tooltipProps}
                data-testid="verifiedPartnerText"
                className={css(styles.bannerPartnerText)}
              >
                {verfiedTextAbbreviated}
              </p>
            )}
          </BasicTooltip>
        ) : (
          <img
            height="1.3rem"
            data-testid="verifiedPartnerLogo"
            src={verfiedLogo}
            alt={capitalizeWords(partner)}
            className={css(styles.bannerPartnerImage)}
          />
        )}
        <h5 className={css(styles.bannerPartnerSlogan)}>{verfiedSlogan}</h5>
      </div>

      <h4 className={css(styles.bannerExplainer)}>
        {`
           Podcast level reach data from ${capitalizeWords(
             partner
           )} podcasts has been independently verified via Podchaser's partner verification program
        `}
      </h4>
    </div>
  );
}
