import PropTypes from "prop-types";
import { useMemo, useContext } from "react";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import SocialLinksPanel from "components/Entities/Page/SocialLinksPanel";
import RequestContext from "pages/RequestContext";

import usePodcastData from "hooks/usePodcastData";

const LINK_TITLE_HEIGHT = 35;
const LINK_ITEM_HEIGHT = 58;

const SocialLinks = ({ podcastId, noPanel }) => {
  const podcast = usePodcastData(podcastId);

  const requestContext = useContext(RequestContext);

  const links = useMemo(() => {
    const innerLinks = {};

    if (podcast.get("web_url")) {
      innerLinks.web = { url: podcast.get("web_url") };
    }
    if (podcast.get("feed_url")) {
      innerLinks.feed = { url: podcast.get("feed_url") };
    }

    // eslint-disable-next-line array-callback-return
    podcast
      .getIn(["settings", "additional_links", "social_links"])
      ?.map((handle, network) => {
        if (network && handle && network !== "extra_links" && handle.get) {
          innerLinks[network] = {
            url: handle.get("url"),
            handle: handle.get("username"),
          };
        }
      });

    if (podcast.getIn(["settings", "additional_links", "extra_links"])) {
      innerLinks.otherLinks = podcast
        .getIn(["settings", "additional_links", "extra_links"])
        .toJS();
    }

    return innerLinks;
  }, [podcast]);

  if (Object.keys(links).length > 0) {
    const otherLinksLength = links.otherLinks ? links.otherLinks.length - 1 : 0;
    const numberLinks = Object.keys(links).length + otherLinksLength;
    const height = numberLinks * LINK_ITEM_HEIGHT + LINK_TITLE_HEIGHT;

    return (
      <LazyLoadComponent
        key="SocialLinks"
        height={height}
        renderOnMount={requestContext.isBotCrawler}
      >
        <SocialLinksPanel
          title="Additional Links"
          links={links}
          noPanel={noPanel}
        />
      </LazyLoadComponent>
    );
  }

  return null;
};

SocialLinks.propTypes = {
  podcastId: PropTypes.number.isRequired,
  noPanel: PropTypes.bool,
};

SocialLinks.defaultProps = {
  noPanel: false,
};

export default SocialLinks;
