import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    ...gStyles.skeletonPlaceholder,
    width: "100%",
    height: "13rem",

    [ScreenSizes.mdAndAbove]: {
      height: "13.9rem",
    },
  },
};

const AsyncMobileLoadingEntityCard = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  return <div className={css(styles.container)} />;
};

export default AsyncMobileLoadingEntityCard;
