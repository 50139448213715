import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";

import Dotdotdot from "components/Common/Dotdotdot";
import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import EntityImage from "components/Entities/EntityImage";

import EpisodeTypeBadge from "../../Podcast/EpisodeTypeBadgeContainer";
import CardTitleLine from "./CardTitleLine";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import cardStyles, { cardImageSizes, cardSizes } from "styles/CardStyles";
import colours from "styles/colours";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  entityOverviewTemplate: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    color: colours.black,
    position: "relative",
  },
  entityImage: {
    width: cardImageSizes.mobileCardEntityOverviewEntity,
    minWidth: cardImageSizes.mobileCardEntityOverviewEntity,
    height: cardImageSizes.mobileCardEntityOverviewEntity,
    marginRight: cardSizes.mobilePaddingBetweenEntityOverviewThumbnailAndInfo,

    [ScreenSizes.mdAndAbove]: {
      width: cardImageSizes.cardEntityOverviewEntity,
      minWidth: cardImageSizes.cardEntityOverviewEntity,
      height: cardImageSizes.cardEntityOverviewEntity,
      marginRight: cardSizes.paddingBetweenEntityOverviewThumbnailAndInfo,
    },
  },
  entityInfo: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "0.25rem",
  },
  entityTitle: {
    ...cardStyles.desktopCardPrimaryTitleSmaller,
    marginBottom: ".375rem",
    display: "flex",
    flexDirection: "row",
  },
  entityTitleMobile: {
    ...cardStyles.mobileCardPrimaryTitleSmaller,
    marginBottom: ".25rem",
  },
  entityTitleOnBottom: {
    margin: 0,
  },
  entityTitleMobileOnBottom: {
    marginTop: 0,
  },
  entitySubtitle: {
    ...cardStyles.desktopCardDescription,
  },
  entitySubtitleMobile: {
    ...cardStyles.mobileCardDescription,
    fontSize: ".625rem",
  },
  entitySubtitleOnTop: {
    ...cardStyles.episodePodcastNameSmall,
    ...cardStyles.episodePodcastNamePosition,
    lineHeight: "normal",
  },
  entitySubtitleMobileOnTop: {
    ...cardStyles.episodePodcastNameSmall,
    ...cardStyles.episodePodcastNamePosition,
    lineHeight: "normal",
  },
};

const ENTITY_LINK_PROPS = { noLinkFont: true };

const episodeTypeBadgeStyles = {
  outer: {
    margin: "0 0.5rem 0.375rem 0",
    alignSelf: "flex-start",
  },
};

const EntityOverviewTemplate = (props) => {
  const {
    entity,
    entity_type,
    title,
    inlineWithTitle,
    subtitle,
    subtitle_entity,
    subtitle_entity_type,
    mobile,
    subtitleAboveTitle,
    children,
    hidePowerScore,
    renderOnMount,
  } = props;

  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrMore } = useWindowSize();

  const renderTitle = () => (
    <div
      className={css(
        styles.entityTitle,
        mobile && styles.entityTitleMobile,
        subtitleAboveTitle && styles.entityTitleOnBottom,
        subtitleAboveTitle && mobile && styles.entityTitleMobileOnBottom
      )}
    >
      {entity_type === "episode" && (
        <LazyLoadComponent renderOnMount={renderOnMount} height={15}>
          <EpisodeTypeBadge episode={entity} styles={episodeTypeBadgeStyles} />
        </LazyLoadComponent>
      )}
      {title || (
        <CardTitleLine
          entity={entity}
          entityType={entity_type}
          size={12}
          badgeLabelSize={11}
          renderOnMount={renderOnMount}
          noLinkFont
        />
      )}
      {inlineWithTitle}
    </div>
  );

  const renderSubtitle = () => (
    <Dotdotdot
      clamp={3}
      tagName="div"
      renderOnMount={renderOnMount}
      className={css(
        styles.entitySubtitle,
        mobile && styles.entitySubtitleMobile,
        subtitleAboveTitle && styles.entitySubtitleOnTop,
        subtitleAboveTitle && mobile && styles.entitySubtitleMobileOnTop
      )}
    >
      {subtitle_entity && subtitle_entity_type ? (
        <CardTitleLine
          entity={subtitle_entity}
          entityType={subtitle_entity_type}
          // avalon
          entityLinkProps={ENTITY_LINK_PROPS}
          size={12}
          badgeLabelSize={11}
          hidePowerScore={hidePowerScore}
          renderOnMount={renderOnMount}
        />
      ) : (
        subtitle
      )}
    </Dotdotdot>
  );

  return (
    <div className={css(styles.entityOverviewTemplate)}>
      <EntityImage
        className={css(styles.entityImage)}
        entity_type={entity_type}
        entity={entity}
        size={
          isWindowSizeOrMore("medium")
            ? cardImageSizes.cardEntityOverviewEntity
            : cardImageSizes.mobileCardEntityOverviewEntity
        }
        fullWidth={false}
      />
      <div className={css(styles.entityInfo)}>
        {subtitleAboveTitle ? renderSubtitle() : renderTitle()}
        {subtitleAboveTitle ? renderTitle() : renderSubtitle()}
      </div>
      {children}
    </div>
  );
};

EntityOverviewTemplate.propTypes = {
  entity_type: PropTypes.string.isRequired,
  entity: PropTypes.instanceOf(Map).isRequired,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  subtitle_entity: PropTypes.instanceOf(Map),
  subtitle_entity_type: PropTypes.string,
  mobile: PropTypes.bool,
  subtitleAboveTitle: PropTypes.bool,
  inlineWithTitle: PropTypes.node,
  children: PropTypes.node,
  hidePowerScore: PropTypes.bool,
  renderOnMount: PropTypes.bool,
};

EntityOverviewTemplate.defaultProps = {
  title: null,
  subtitle: null,
  subtitle_entity: null,
  subtitle_entity_type: null,
  mobile: false,
  subtitleAboveTitle: false,
  inlineWithTitle: null,
  children: null,
  hidePowerScore: false,
  dataId: "entity-overview",
  renderOnMount: false,
};

export default EntityOverviewTemplate;
