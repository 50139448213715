import addMetricPostfix from "../../../../utils/misc/addMetricPostfix";

import getPodcastAuthorityLevel from "utils/entity/getPodcastAuthorityLevel";
import getPodcastHasGuests from "utils/entity/getPodcastHasGuests";
import getPodcastUrl from "utils/entity/getPodcastUrl";

export const getIcons = (podcast) =>
  [
    getPodcastAuthorityLevel(podcast, 0) && {
      type: "authority",
      value: getPodcastAuthorityLevel(podcast, 0),
      to: getPodcastUrl(podcast, "/insights"),
      proOnly: true,
    },
    podcast.get("review_count") && {
      type: "reviews",
      text:
        podcast.get("review_count") &&
        addMetricPostfix(podcast.get("review_count")),
      value: podcast.get("review_count"),
      to: getPodcastUrl(podcast, "/reviews"),
    },
    podcast.get("list_count") && {
      type: "lists",
      text:
        podcast.get("list_count") &&
        addMetricPostfix(podcast.get("list_count")),
      value: podcast.get("list_count"),
      to: getPodcastUrl(podcast, "/lists"),
    },
    podcast.get("follower_count") && {
      type: "followers",
      text:
        podcast.get("follower_count") &&
        addMetricPostfix(podcast.get("follower_count")),
      value: podcast.get("follower_count"),
      to: getPodcastUrl(podcast),
    },
    getPodcastHasGuests(podcast) !== undefined && {
      type: "hasGuests",
      value: getPodcastHasGuests(podcast),
      to: getPodcastUrl(podcast, "/insights"),
      proOnly: true,
    },
  ].filter((iconType) => iconType);
