import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo, useState, memo } from "react";
import Helmet from "react-helmet-async";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import StandardButton from "components/Buttons/StandardButton";
import BasicTooltip from "components/Common/Tooltip/BasicTooltip";
import EntityLink from "components/Entities/EntityLink";
import PageHeader from "components/Entities/Page/PageHeader";
import PageHeaderTabMenu from "components/Entities/Page/PageHeaderTabMenu";
import EntityFollowButton from "components/Follow/EntityFollowButton";
import AuthorityBadge from "components/Podcast/AuthorityBadgeAsync";
import ClaimedBadge from "components/Podcast/ClaimedBadgeAsync";
import PodcastButtons from "components/Podcast/PodcastButtonsAsync";

import HasGuestsBadge from "../../components/Podcast/HasGuestsBadgeAsync";
import entityPageStyles from "../../styles/entityPageStyles";
import generateTransition from "../../utils/generateTransition";
import HeaderAuthorityScore from "./HeaderAuthorityScoreAsync";
import HeaderVerifiedBadge from "./HeaderVerifiedBadgeAsync";
import PodcastSummary from "./PodcastSummary";
import RatingAuthorityContainer from "./RatingAuthorityContainer";
import ProConcierge from "./Sidebar/ProConciergeAsync";

import ratingActions from "actions/rating";
import { MISSING_IMAGE_URL } from "constants/base";
import cachedImage from "utils/entity/cachedImage";
import getEntityImageTitle, {
  getEntityImageAlt,
} from "utils/entity/getEntityImageTitle";
import { getNetworkUrlByEntity } from "utils/entity/getNetworkUrl";
import getPodcastAuthorityLevel from "utils/entity/getPodcastAuthorityLevel";
import { capitalize } from "utils/misc";
import { getRatingIcon, podcastVerbLabels } from "utils/rating";
import sendGAEvent from "utils/sendGAEvent";
import { truncateString } from "utils/truncate";
import { matchesRouteWithParams } from "utils/url";

import useActionCreators from "hooks/useActionCreators";
import useHasProForPodcast from "hooks/useHasProForPodcast";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ...entityPageStyles,
  mediumHeaderContent: {
    ...entityPageStyles.mediumHeaderContent,
    marginTop: 0,
  },
  author: {
    ...gStyles.fontBold,
    color: colours.white,
    marginTop: 0,
  },
  authorMobileAbout: {
    ...gStyles.fontBold,
    color: colours.black,
    margin: "0 0 0.5rem 0",
    minHeight: "1.25rem",
  },
  authorMobile: {
    ...gStyles.textEllipsis,
    ...gStyles.fontRegular,
    fontSize: ".75rem",
    margin: "0 0 0.25rem 0",
  },
  authorOpacity: {
    opacity: 0.5,
  },
  name: {
    ...entityPageStyles.name,
    whiteSpace: "normal",
    color: colours.black,
    fontSize: "1.25rem",
    margin: 0,
    display: "inline",

    [ScreenSizes.smAndAbove]: {
      fontSize: "1.5rem",
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: "1.8rem",
      color: colours.white,
      marginRight: "1rem",
    },
    [ScreenSizes.xlAndAbove]: {
      fontSize: "2rem",
    },
  },
  smallHeaderNameHeading: {
    fontSize: ".875rem",
    margin: 0,
    padding: 0,
    lineHeight: "auto",
    display: "inline",
  },
  mainTextContainer: {
    ...entityPageStyles.mainTextContainer,
    [ScreenSizes.lgAndAbove]: {
      ...entityPageStyles.mainTextContainer[ScreenSizes.lgAndAbove],
      marginBottom: "1.25rem",
      justifyContent: "flex-start",
    },
    [ScreenSizes.xlAndAbove]: {
      ...entityPageStyles.mainTextContainer[ScreenSizes.xlAndAbove],
      marginRight: 0,
    },
  },
  nameOuter: {
    display: "flex",
    alignItems: "center",
  },
  mediumButtons: {
    display: "flex",
    flexDirection: "column",
    minWidth: "calc(100vw - 4.25rem)",
    marginTop: "1.75rem",
  },
  mediumButtonSeparator: {
    marginTop: ".5rem",
  },
  claimedBadge: {
    lineHeight: 1,

    [ScreenSizes.lgAndAbove]: {
      fontSize: "1.25rem",
    },
  },
  description: {
    ...entityPageStyles.description,
    color: colours.black,
  },
  backgroundImage: {
    position: "absolute",
    left: 0,
    right: 0,
    backgroundPosition: "center",
    opacity: ".07",
    filter: "blur(6px) saturate(0.3)",
    top: "-200px",
    bottom: "-200px",
    backgroundSize: "cover",
    transform: "rotate(-10deg)",
  },
  ratingAuthorityBadgesContainer: {
    display: "inline-flex",
    marginLeft: "0.5rem",
    alignItems: "center",
    justifyContent: "center",
    [ScreenSizes.lgAndAbove]: {
      display: "none",
    },
  },
  contactButtonContainer: {
    margin: "1rem 0",
    position: "relative",
  },
  mainHeaderImage: {
    position: "relative",
    top: "1rem",

    width: "10rem",
    height: "10rem",

    margin: "0 auto",

    border: `3px solid ${colours.white}`,
    boxShadow: "0 1px 4px 0 rgba(0,0,0,0.2)",
    borderRadius: 8,

    backgroundColor: colours.offWhite,
    backgroundSize: "cover",
    backgroundPosition: "center",

    zIndex: 10,
    cursor: "pointer",

    transition: generateTransition({
      targets: ["width", "height"],
      speed: "150ms",
    }),

    [ScreenSizes.lgAndAbove]: {
      width: "12rem",
      height: "12rem",

      margin: 0,
      borderWidth: 4,
      position: "absolute",
      top: 0,
    },

    [ScreenSizes.xlAndAbove]: {
      width: "13rem",
      height: "13rem",
    },
  },
  image: {
    width: "100%",
    height: "auto",
    borderRadius: 8,

    [ScreenSizes.lgAndAbove]: {
      borderRadius: 8,
    },
  },
  smallImage: {
    borderRadius: 8,

    [ScreenSizes.lgAndAbove]: {
      borderRadius: 8,
    },
  },
  smallMainHeaderImage: {
    position: "relative",
    top: 0,

    borderRadius: 8,

    height: "4rem",
    width: "4rem",

    [ScreenSizes.lgAndAbove]: {
      borderRadius: 8,
    },
  },
  buttonWithBadge: {
    position: "relative",
  },
  buttonBadge: {
    ...gStyles.fontSemiBold,
    position: "absolute",
    background: colours.primaryTextBackground,
    color: colours.white,
    fontSize: 9,
    margin: "0 auto",
    borderRadius: "2rem",
    textTransform: "uppercase",
    minWidth: 6,
    minHeight: 6,
    zIndex: 2,
    marginLeft: -6,
    left: "1rem",
    padding: "0.3rem 0.4rem",
    top: "-0.5rem",
  },
  createdBy: {
    overflow: "hidden",
    margin: "0.75rem auto 1rem",
    [ScreenSizes.mdAndAbove]: {
      maxWidth: "90%",
    },
    [ScreenSizes.lgAndAbove]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      margin: ".8rem 0 1rem",
      maxWidth: "100%",
      gap: "0.5rem",
    },
    [ScreenSizes.xlAndAbove]: {
      margin: "-1rem 0 1.5rem",
    },
  },

  subtitleLink: {
    ...gStyles.fontSemiBold,

    ":hover": {
      textDecoration: "underline",
    },
  },
};

const headerStyles = {
  ...entityPageStyles,
  largeContainer: {
    minHeight: "12.5rem",

    [ScreenSizes.xlAndAbove]: {
      minHeight: "13.5rem",
    },
  },
  mainHeaderSpan: {
    borderRadius: 1,
  },
  infoContainer: {
    marginLeft: "14rem",

    [ScreenSizes.xlAndAbove]: {
      marginLeft: "15.125rem",
    },
  },
  smallHeader: {
    marginTop: 0,
    paddingBottom: 0,
    paddingTop: "0.1rem",
  },
  smallBackArrow: {
    paddingTop: ".6rem",
    paddingBottom: ".3rem",
  },
  mobileContainer: {
    display: "block",

    [ScreenSizes.smAndAbove]: {
      display: "block",
    },

    [ScreenSizes.lgAndAbove]: {
      display: "none",
    },
  },
  tabletContainer: {
    display: "none",

    [ScreenSizes.smAndAbove]: {
      display: "none",
    },
    [ScreenSizes.lgAndAbove]: {
      display: "none",
    },
  },
};

const aboutPageHeaderStyles = {
  ...headerStyles,
  tabletContainer: {
    display: "block",

    [ScreenSizes.smAndAbove]: {
      display: "block",
    },

    [ScreenSizes.lgAndAbove]: {
      display: "none",
    },
  },
  mobileContainer: {
    display: "none",

    [ScreenSizes.smAndAbove]: {
      display: "none",
    },
  },
};

const mediumContainerStyles = {
  container: {
    paddingTop: "0.5rem",
    marginBottom: "-2rem",

    [ScreenSizes.lgAndAbove]: {
      paddingTop: 0,
      marginBottom: 0,
      height: "16rem",
    },
  },
  smallHeader: {
    marginTop: 0,
    paddingBottom: 0,
  },
};

const mediumContentStyles = {
  contentContainer: {
    paddingTop: "3.5rem",

    [ScreenSizes.smAndAbove]: {
      paddingTop: "3.5rem",
    },
    [ScreenSizes.mdOnly]: {
      paddingTop: "3.25rem",
    },
    [ScreenSizes.lgOnly]: {
      paddingTop: "3.25rem",
    },
    [ScreenSizes.mdAndAbove]: {
      paddingTop: "3.25rem",
    },
    [ScreenSizes.xlAndAbove]: {
      padding: "3rem",
    },
  },
  smallHeader: {
    marginTop: 0,
    paddingBottom: 0,
  },
};

const buttonStyles = {
  button: {
    ...gStyles.avalonBold,
  },
};

const ratePodcastButtonStyles = {
  button: {
    ...buttonStyles.button,
    display: "block",
    height: "3em",
  },
};

const smallOverrideStyles = {
  container: {
    ...gStyles.gradientBackground,
  },
  contentContainer: {
    backgroundColor: colours.white,
    padding: "0 1rem",

    [ScreenSizes.mdOnly]: {
      padding: "0 1rem",
    },
  },
};

const imageToggledHeaderStyles = {
  mainHeaderImage: {
    width: "12rem",
    height: "12rem",

    [ScreenSizes.smAndMd]: {
      width: "14rem",
      height: "14rem",
    },
  },
};

const RatingAuthorityContainerStyles = {
  hasGuestsBadge: {
    marginLeft: "1rem",
  },
  iconContainer: {
    fontSize: "1.5rem",
    marginLeft: "0.5rem",
  },
  authorityContainer: {
    display: "flex",
    alignItems: "center",
    height: 35,
  },
  powerCircle: {
    width: 35,
    height: 35,
    fontSize: "1.2rem",
  },
  authorityValue: {
    fontSize: "1.2rem",
  },
};

const proBaseStyles = {
  mainTextContainer: {
    [ScreenSizes.lgAndAbove]: {
      justifyContent: "flex-start",
    },
  },
  ratingAuthorityContainer: {
    [ScreenSizes.lgAndAbove]: {
      justifyContent: "flex-end",
    },
  },
};

function getImageUrl(imageUrl, mainImageSize) {
  return cachedImage(imageUrl, mainImageSize);
}

export function getBackgroundImageStyle(imageUrl, imageUrlRetina) {
  return {
    backgroundImage: {
      backgroundImage: `url(${imageUrl})`,

      [ScreenSizes.retinaOnlyMdAndAbove]: {
        backgroundImage: `url(${imageUrlRetina})`,
      },
    },
  };
}

const MAIN_SIZE_IMAGE = 256;

const SIZE_SMALL = "small";
const SIZE_MEDIUM = "medium";

const PodcastHeader = (props) => {
  const {
    entity: podcast,
    onTabChange,
    tabs,
    baseRoute,
    mobilePodcastFollowButton,
  } = props;

  const location = useLocation();

  const hasProPermission = useHasProForPodcast(podcast.get("id"));

  const { isWindowSize, isWindowSizeOrLess } = useWindowSize();

  const isSmall = isWindowSizeOrLess("small");
  const isMedium = isWindowSizeOrLess("medium");

  const src = podcast.get("image_url");
  const isMissingImage = src === MISSING_IMAGE_URL;

  const podcastHeaderImageUrl = isMissingImage
    ? MISSING_IMAGE_URL
    : getImageUrl(podcast.get("image_url"), MAIN_SIZE_IMAGE);

  const podcastHeaderImageUrlRetina = isMissingImage
    ? MISSING_IMAGE_URL
    : getImageUrl(podcast.get("image_url"), MAIN_SIZE_IMAGE * 2);

  const [imageToggled, setImageToggled] = useState(false);

  const score = getPodcastAuthorityLevel(podcast, 0);

  const { styles } = useStyles(
    [baseStyles, hasProPermission && proBaseStyles],
    props
  );

  const networks = podcast.get("networks");

  const { openPodcastRatingModal } = useActionCreators(ratingActions);

  const isOnInsightsTab = useMemo(
    () => matchesRouteWithParams(location.pathname, "/podcasts/:id/insights"),
    [location]
  );

  const isClaimed = podcast.get("is_claimed");

  let claimedLocation;
  let claimBadgeProps;

  if (isWindowSizeOrLess("medium")) {
    claimedLocation = "subtitle";
    claimBadgeProps = {
      showLabel: false,
    };
  } else if (isWindowSize("large")) {
    claimedLocation = "right";
    claimBadgeProps = {
      showLabel: true,
      size: 20,
    };
  } else {
    // More than xlarge
    claimedLocation = "title";
    claimBadgeProps = {
      showLabel: true,
    };
  }

  const userRatingData = podcast.getIn(["user_data", "rating"]);
  const userRating = userRatingData && userRatingData.get("rating");

  const isOnAboutTab = useMemo(
    () =>
      matchesRouteWithParams(location.pathname, "/podcasts/:id/about") ||
      matchesRouteWithParams(location.pathname, "/podcasts/:id", true),
    [location]
  );

  const HeadingComponent = isOnAboutTab ? "h1" : "h2";
  const { yourRatingButtonStyles, ratingBackgroundColor } = useMemo(() => {
    const customButtonColor = userRating ? "#fff" : null;
    const customButtonBackgroundColor = userRating ? colours.actioned : null;

    return {
      ratingBackgroundColor: customButtonBackgroundColor ? null : "white",
      yourRatingButtonStyles: {
        button: {
          ...(customButtonBackgroundColor && {
            color: customButtonColor,
            backgroundColor: customButtonBackgroundColor,
          }),
          height: "3em",
        },
      },
    };
  }, [userRating]);

  const handleRatingClick = useCallback(
    () => openPodcastRatingModal(podcast.get("id"), { entity: podcast }),
    [openPodcastRatingModal, podcast]
  );
  const handleSmallTitleClick = useCallback(() => {
    onTabChange("about");
  }, [onTabChange]);
  const handleMainImageClick = useCallback(() => {
    setImageToggled(!imageToggled);
  }, [imageToggled]);

  const renderTitleClaimBadge = () => {
    if (podcast.get("is_claimed")) {
      return (
        <span
          className={css(
            styles.claimedBadge,
            claimedLocation &&
              styles[`claimedBadge${capitalize(claimedLocation)}`]
          )}
        >
          <ClaimedBadge padded {...claimBadgeProps} />
        </span>
      );
    }
    return null;
  };

  const renderHeaderWithToolTip = useCallback(() => {
    return (
      <BasicTooltip renderTooltip={() => podcast?.get("title")}>
        {(tooltipProps) => (
          <span {...tooltipProps}>
            <HeadingComponent
              className={css(styles.name)}
              title={podcast.get("title")}
            >
              {truncateString(podcast.get("title"), 60)}
            </HeadingComponent>
          </span>
        )}
      </BasicTooltip>
    );
  }, [podcast, styles.name]);

  const renderHeaderWithoutToolTip = useCallback(() => {
    return (
      <HeadingComponent
        className={css(styles.name)}
        title={podcast.get("title")}
      >
        {truncateString(podcast.get("title"), 60)}
      </HeadingComponent>
    );
  }, [podcast, styles.name]);

  const renderPodcastName = () => (
    <div className={css(styles.nameOuter)}>
      {podcast?.get("title")?.length > 60
        ? renderHeaderWithToolTip()
        : renderHeaderWithoutToolTip()}
      {isClaimed && claimedLocation === "title" && renderTitleClaimBadge()}
      {hasProPermission && (
        <div className={css(styles.ratingAuthorityBadgesContainer)}>
          {<HeaderVerifiedBadge podcast={podcast} />}
          <HeaderAuthorityScore podcast={podcast} />
          <HasGuestsBadge podcast={podcast} />
        </div>
      )}
    </div>
  );

  const networkLinkClick = () => {
    sendGAEvent({
      action: "linkClick",
      page: "podcast",
      context: "NetworkLinkClick",
      pageType: "PodcastView",
      ...(podcast && {
        podcast_id: podcast.get("id"),
        podcast_name: podcast.get("title"),
      }),
    });
  };

  const renderSmallHeaderContent = () => (
    <Fragment>
      <div className={css(styles.smallHeaderName)}>
        <HeadingComponent
          onClick={handleSmallTitleClick}
          className={css(styles.smallHeaderNameHeading)}
        >
          <p className={css(styles.authorMobile)}>
            <span className={css(styles.authorOpacity)}>
              {podcast?.get("itunes_owner_name")}
            </span>{" "}
            {networks?.size > 0 ? (
              <span>
                {networks.map((network) => {
                  return (
                    <span key={network?.get("id")}>
                      {" • "}
                      <Link
                        to={`${getNetworkUrlByEntity(network)}/podcasts`}
                        className={css(styles.subtitleLink)}
                        onClick={networkLinkClick}
                      >
                        {network?.get("title")}
                      </Link>
                    </span>
                  );
                })}
              </span>
            ) : (
              ""
            )}
          </p>
          {podcast.get("title")}
        </HeadingComponent>
        {renderTitleClaimBadge()}
        {score && (
          <AuthorityBadge
            entity_type="podcast"
            entity={podcast}
            score={getPodcastAuthorityLevel(podcast, 0)}
            tooltipText={`Power Score: ${getPodcastAuthorityLevel(podcast, 2)}`}
            showTooltip
            disableLink
          />
        )}
      </div>
      <PageHeaderTabMenu
        tabs={tabs}
        onTabChange={onTabChange}
        baseRoute={baseRoute}
        ariaLabel="Choose Podcast Detail Page"
        noMenu
      />
    </Fragment>
  );

  const youRatedString = useMemo(
    () =>
      userRating &&
      `You ${podcastVerbLabels[userRating].toLowerCase()} this podcast`,
    [userRating]
  );

  const renderMediumContent = () => (
    <div className={css(styles.mediumHeaderContent)}>
      <p className={css(styles.authorMobileAbout)}>
        <span className={css(styles.authorOpacity)}>
          {podcast?.get("itunes_owner_name")}
        </span>{" "}
        {networks?.size > 0 ? (
          <span>
            {networks.map((network) => {
              return (
                <span key={network?.get("id")}>
                  {" • "}
                  <Link
                    to={`${getNetworkUrlByEntity(network)}/podcasts`}
                    className={css(styles.subtitleLink)}
                    onClick={networkLinkClick}
                  >
                    {network?.get("title")}
                  </Link>
                </span>
              );
            })}
          </span>
        ) : (
          ""
        )}
      </p>
      <div className={css(styles.mediumNameContainer)}>
        {renderPodcastName()}
      </div>
      <div className={css(styles.subtitle)}>
        <div className={css(styles.description)}>
          <PodcastSummary podcast={podcast} maxLength={isSmall ? 60 : 80} />
        </div>
        {isClaimed && claimedLocation === "subtitle" && renderTitleClaimBadge()}
      </div>
      <RatingAuthorityContainer
        podcast={podcast}
        titleClaimBadge={
          claimedLocation === "right" ? renderTitleClaimBadge() : null
        }
        handleRatingClick={handleRatingClick}
      />
      <div className={css(styles.mediumButtons)}>
        <EntityFollowButton
          entityName="Podcast"
          entity_type="podcast"
          entity={podcast}
          variation="pink"
          fontSize=".938rem"
          flat
          customStyles={buttonStyles}
          buttonRef={mobilePodcastFollowButton}
        />
        <div
          className={css(styles.mediumButtonSeparator)}
          key="PodcastHeaderEntityFollowButtonSeparator"
        />
        {userRating ? (
          <StandardButton
            variation={ratingBackgroundColor}
            onClick={handleRatingClick}
            fontSize=".8125rem"
            label={
              <span>
                <FontAwesomeIcon
                  icon={getRatingIcon(userRating)}
                ></FontAwesomeIcon>{" "}
                {youRatedString}
              </span>
            }
            flat
            customStyles={yourRatingButtonStyles}
            key="PodcastHeaderRateChangeButton"
            rounded
          />
        ) : (
          <StandardButton
            variation={ratingBackgroundColor}
            label="Rate Podcast"
            onClick={handleRatingClick}
            fontSize=".8125rem"
            flat
            customStyles={ratePodcastButtonStyles}
            outerComponent="div"
            key="PodcastHeaderRateButton"
            rounded
          />
        )}

        <PodcastButtons entity_type="podcast" entity={podcast} />
      </div>
    </div>
  );

  const renderLargeContent = () => (
    <div className={css(styles.headerContainer)}>
      <div className={css(styles.mainTextContainer)}>
        <p className={css(styles.author)}>
          <span className={css(styles.authorOpacity)}>
            {podcast?.get("itunes_owner_name")}
          </span>{" "}
          {networks?.size > 0 ? (
            <span>
              {networks.map((network) => {
                return (
                  <span key={network?.get("id")}>
                    {" • "}
                    <Link
                      to={`${getNetworkUrlByEntity(network)}/podcasts`}
                      className={css(styles.subtitleLink)}
                      onClick={networkLinkClick}
                    >
                      {network?.get("title")}
                    </Link>
                  </span>
                );
              })}
            </span>
          ) : (
            ""
          )}
        </p>
        {renderPodcastName()}
        <div className={css(styles.subtitle)}>
          <div className={css(styles.description)}>
            <PodcastSummary podcast={podcast} maxLength={80} />
          </div>
          <br />
        </div>
      </div>
      <RatingAuthorityContainer
        podcast={podcast}
        titleClaimBadge={
          claimedLocation === "right" ? renderTitleClaimBadge() : null
        }
        handleRatingClick={handleRatingClick}
        styles={RatingAuthorityContainerStyles}
      />
    </div>
  );

  const { size, containerStyles, overrideStyles } = useMemo(() => {
    let pageSize = "large";
    let contStyles = null;
    let overStyles = null;

    if (isMedium) {
      if (isOnAboutTab) {
        pageSize = SIZE_MEDIUM;
        contStyles = mediumContainerStyles;
      } else {
        pageSize = SIZE_SMALL;
        overStyles = smallOverrideStyles;
      }
    }

    return {
      size: pageSize,
      containerStyles: contStyles,
      overrideStyles: overStyles,
    };
  }, [isMedium, isOnAboutTab]);

  const renderBackgroundImage = useCallback(
    () => <div className={css(styles.backgroundImage)} />,
    [styles.backgroundImage]
  );

  const { alt, title } = useMemo(() => {
    return {
      alt: getEntityImageAlt(podcast, "podcast"),
      title: getEntityImageTitle(podcast, "podcast"),
    };
  }, [podcast]);

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          as="image"
          href={podcastHeaderImageUrl}
          imagesrcset={`${podcastHeaderImageUrl} 1x, ${podcastHeaderImageUrlRetina} 2x`}
          media="(min-width: 640px)"
        />
        <link
          rel="preload"
          as="image"
          href={podcastHeaderImageUrl}
          media="(max-width: 639px)"
        />
      </Helmet>
      <PageHeader
        entity_type="podcast"
        entity={podcast}
        styles={[
          isOnAboutTab ? aboutPageHeaderStyles : headerStyles,
          imageToggled && imageToggledHeaderStyles,
        ]}
        containerStyles={containerStyles}
        mediumContentStyles={mediumContentStyles}
        size={size}
        renderContent={renderLargeContent}
        renderMediumContent={renderMediumContent}
        renderAboveSmallHeader={
          isOnInsightsTab
            ? () => <ProConcierge podcastId={podcast.get("id")} mobile />
            : null
        }
        renderSmallHeaderContent={renderSmallHeaderContent}
        onSmallLayoutBackClick={() => onTabChange("about")}
        renderBackgroundImage={renderBackgroundImage}
        updateProps={podcast}
        overrideStyles={overrideStyles}
        siteHeaderPadding
        renderProfileImage={() => {
          return (
            <div
              className={css(
                styles.mainHeaderImage,
                size === SIZE_SMALL && styles.smallMainHeaderImage
              )}
              onClick={handleMainImageClick}
            >
              <EntityLink
                entity_type="podcast"
                entity={podcast}
                block
                expand
                isImage
              >
                <picture>
                  <source
                    media="(min-width: 640px)"
                    srcSet={`${podcastHeaderImageUrl} 1x, ${podcastHeaderImageUrlRetina} 2x`}
                    className={css(
                      styles.image,
                      size === SIZE_SMALL && styles.smallImage
                    )}
                    alt={alt}
                    title={title}
                    width={154}
                    height={154}
                  />
                  <img
                    src={podcastHeaderImageUrl}
                    className={css(
                      styles.image,
                      size === SIZE_SMALL && styles.smallImage
                    )}
                    alt={alt}
                    title={title}
                    width={154}
                    height={154}
                  />
                </picture>
              </EntityLink>
            </div>
          );
        }}
      />
    </>
  );
};

PodcastHeader.propTypes = {
  entity: PropTypes.instanceOf(Map).isRequired,
  location: PropTypes.object,
  tabs: PropTypes.array.isRequired,
  onTabChange: PropTypes.func.isRequired,
  baseRoute: PropTypes.string.isRequired,
  mobilePodcastFollowButton: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default memo(PodcastHeader);
