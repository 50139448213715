import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { loadTellMeWhyPromise } from "routines/podcast";

import StandardButton from "components/Buttons/StandardButton";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useIsModerator from "hooks/useIsModerator";
import useLoggedInUser from "hooks/useLoggedInUser";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";
import userHasInternalPermission from "hooks/useUserHasInternalPermission";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  modsTag: {
    ...gStyles.avalonBold,
    color: colours.lightishGrey,
    fontSize: ".65rem",
    textAlign: "end",
  },
};

const ButtonStyles = {
  button: {
    ...gStyles.avalonBold,
    height: "2.625rem",
    width: "10.313rem",
    fontSize: "1rem",
    borderRadius: "25px",
  },
};

const TellMeWhyButton = (props) => {
  const { podcastId } = props;
  const { styles } = useStyles(baseStyles, props);

  const userIsInternal = userHasInternalPermission();
  const { isModerator } = useIsModerator();

  const [submitting, setSubmitting] = useState(false);

  const user = useLoggedInUser();
  const tellMyWhyPrompt = user?.get("profile_prompt");

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const { loadTellMeWhy } = useRoutinePromises({
    loadTellMeWhy: loadTellMeWhyPromise,
  });

  const { showModal, closeModal } = useActionCreators({
    showModal: modalActions.showModal,
    closeModal: modalActions.closeModal,
  });

  const handleTellMeWhyClick = useCallback(() => {
    if (!tellMyWhyPrompt) {
      sendGAEvent({
        action: "tell me why button clicked for initial prompt",
        page: "Podcast Insight Page",
        podcastId,
      });

      showModal("tellMyWhyPrompt", {
        title: "We DO wanna hear you say 'I want it that way'",
        mobile,
        closeModal,
      });
    } else {
      sendGAEvent({
        action: "tell me why button clicked for result",
        page: "Podcast Insight Page",
        podcastId,
      });
      setSubmitting(true);
      loadTellMeWhy({ podcastId: podcastId })
        .then(() => {
          setSubmitting(false);
          showModal("tellMyWhyReason", {
            title: "Here's Why",
            mobile,
            closeModal,
            podcastId,
          });
        })
        .catch((e) => {
          setSubmitting(false);
        });
    }
  }, [
    closeModal,
    loadTellMeWhy,
    mobile,
    podcastId,
    showModal,
    tellMyWhyPrompt,
  ]);

  if (!userIsInternal || !isModerator) {
    return null;
  }

  return (
    <div className={css(styles.buttonContainer)}>
      <div className={css(styles.modsTag)}>Internal Only</div>
      <StandardButton
        label="Tell Me Why"
        variation="pink"
        flat
        withPadding
        customStyles={ButtonStyles}
        submitting={submitting}
        onClick={handleTellMeWhyClick}
      />
    </div>
  );
};

TellMeWhyButton.propTypes = {
  podcast: PropTypes.instanceOf(Map),
};

TellMeWhyButton.defaultProps = {
  podcast: null,
};

export default TellMeWhyButton;
